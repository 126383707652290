.carousel-slider {
    min-height: 10rem;
    background: #add8e6;
    opacity: 0.5;
}

.login-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.login-page div {
    text-align: center;
}

h1, h2 {
    color: white;
}

body {
    background: url('../public/animo-background.jpeg');
    background-size: auto;
    background-repeat: no-repeat;
}
.btn {
    border: none;
    color: white;    
    padding: 1rem 2rem;
    font-size: 2rem;
    text-transform: uppercase;

}
.btn-danger {
    background: red;
}

.btn-secondary {
    background: grey;
}

.btn-primary {
    background: greenyellow;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 3rem 0;
}

.full-modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    height: 100%;
    width: 100%;
}

.check-img {
    max-width: 30vw;
}